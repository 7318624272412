// https://stackoverflow.com/a/44795011/8014660

.scrollbox {
  background: #fff no-repeat;
  background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
  background-position: 0 0, 0 100%;
  background-size: 100% 14px;
  overflow: auto;
  position: relative;
  z-index: 1;
}

.scrollbox:before,
.scrollbox:after {
  background: linear-gradient(to bottom, #fff, #fff 30%, rgba(255, 255, 255, 0));
  content: "";
  display: block;
  height: 30px;
  margin: 0 0 -30px;
  position: relative;
  z-index: -1;
}

.scrollbox:after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 70%, #fff);
  margin: -30px 0 0;
}
