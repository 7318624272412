:global {
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

// Keep navbar-buttons properly inline
.navbar-button {
  line-height: 29px;
}

:global(.navbar-toggler) {
  border: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    opacity: 0.9;
  }
}
