hr {
  margin-bottom: 20px;
  margin-top: 20px;
}

.hr-large {
  margin-bottom: 60px;
  margin-top: 60px;
}

.hr-dashed {
  border-style: dashed;
  border-width: 2px;
  margin-bottom: 30px;
  margin-top: 30px;
}
